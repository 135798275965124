html {
  background-color: #2a2a30;
}

.App {
  text-align: center;
  background-color: #2a2a30;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

label {
  color: #fff;
  font-family: "Bold";
}

small {
  font-family: "Regular";
}

.form-control {
  margin-top: 8px;
  font-family: "Regular";
}

.form-group {
  padding: 8px 0px;
}

.form-select-sm {
  margin-top: 8px;
}

#upstreamUrl {
  background: #3b3b40;
  color: #fff;
}

#upstreamId {
  background: #3b3b40;
  color: #fff;
}

#routeTypes {
  background: #3b3b40;
  color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Regular";
  src: local('HKGroteskRegular'), url(./assets/fonts/HKGrotesk/HKGrotesk-Regular.otf) format('opentype');
}

@font-face {
  font-family: "Bold";
  src: local('HKGroteskBold'), url(./assets/fonts/HKGrotesk/HKGrotesk-Bold.otf) format('opentype');
}